import { __rest } from "tslib";
import { Icon, Tooltip } from "antd";
import * as React from "react";
import s from "./style.scss";
const TooltipDisplay = (_a) => {
    var { type = "question-circle" } = _a, props = __rest(_a, ["type"]);
    return (React.createElement(Tooltip, { overlayClassName: props.classname, trigger: "hover", placement: props.placement || "topRight", className: props.classname, title: props.title ? props.title : "" },
        React.createElement(Icon, { className: s.icon, type: type, "data-cy": props["data-cy"] })));
};
export default TooltipDisplay;
