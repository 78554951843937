import { __awaiter } from "tslib";
import { Icon } from "antd";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CCBButton from "../../../company/components/CCBButton";
import ActionTable from "../../../shared/components/ActionTable";
import { FilterForm } from "../../../shared/components/FilterForm";
import { DownloadIcon } from "../../../shared/components/Icons/Download";
import { MonthSelector } from "../../../shared/components/MonthSelector";
import { Pagination } from "../../../shared/components/Pagination";
import { SectionContainer } from "../../../shared/components/SectionContainer";
import { SectionFooter } from "../../../shared/components/SectionFooter";
import { SummaryItem } from "../../../shared/components/SummaryItem";
import TooltipDisplay from "../../../shared/components/TooltipDisplay";
import { getDateLocalTimezoneFormat } from "../../../shared/utils/date";
import { downloadFileFromBuffer, openFileInNewTab } from "../../../shared/utils/file";
import { nullIfEmpty } from "../../../shared/utils/filter";
import { formatCpf, formatCurrentInstallment, formatStringCurrency, formatStringCurrencyNoPrefix, } from "../../../shared/utils/formatters";
import { mapCompaniesToSelectOptions } from "../../../shared/utils/mappers";
import { filterOption } from "../../../shared/utils/select";
import { SectionHeader } from "../../components/SectionHeader";
import { getAllMonthsUntilDate } from "../../utils/date.utils";
import { useStores } from "../../utils/mobx";
import { columns } from "./column";
import s from "./style.scss";
const PayRollReport = observer(() => {
    const { t } = useTranslation();
    const { generalStore: { setErrorMessage, clearMessage, catchErrors }, payrollReportStore, authStore: { user }, } = useStores();
    const [months, setMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [payrollSummary, setPayrollSummary] = useState({
        totalAmount: 0,
        totalItems: 0,
    });
    const [payrollInfo, setPayrollInfo] = useState([]);
    const [loadingPayrollInfo, setLoadingPayrollInfo] = React.useState(true);
    const [currentFilter, setCurrentFilter] = React.useState({
        companies: [],
        companySector: "",
    });
    const items = [
        {
            key: "companies",
            name: "companies",
            type: "select",
            placeholder: "Empresa",
            mode: "multiple",
            selectOptions: mapCompaniesToSelectOptions(user === null || user === void 0 ? void 0 : user.companies),
            props: {
                filterOption,
            },
        },
        {
            key: "companySector",
            name: "companySector",
            type: "input",
            placeholder: t("sectorLabel"),
        },
    ];
    useEffect(() => {
        getOldestMonthWithActiveInstallment(currentFilter);
    }, []);
    useEffect(() => {
        if (selectedMonth) {
            getAllContracts(currentPage, selectedMonth, currentFilter);
        }
    }, [rowsPerPage, currentPage, selectedMonth, currentFilter]);
    const applyFilter = (values) => {
        const filter = {
            companies: values.companies || null,
            companySector: nullIfEmpty(values.companySector),
        };
        setCurrentPage(1);
        setRowsPerPage(10);
        setCurrentFilter(filter);
    };
    const getOldestMonthWithActiveInstallment = (filter) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingPayrollInfo(true);
        try {
            const firstMonth = yield payrollReportStore.getOldestMonthWithActiveInstallment(filter);
            if (firstMonth) {
                const newMonths = getAllMonthsUntilDate(firstMonth);
                const actualMonth = newMonths[0] || firstMonth;
                setSelectedMonth(actualMonth);
                setMonths(newMonths);
            }
            else {
                setLoadingPayrollInfo(false);
            }
        }
        catch (error) {
            catchErrors(error, setErrorMessage, "Oops! Erro ao carregar relatório.");
            setLoadingPayrollInfo(false);
        }
    });
    const getAllContracts = (currentPageToFilter, selectedMonthToFilter, filter) => __awaiter(void 0, void 0, void 0, function* () {
        clearMessage();
        setLoadingPayrollInfo(true);
        try {
            const summary = yield payrollReportStore.getPayrollSummary(selectedMonthToFilter, filter);
            setPayrollSummary(summary);
            const payrollReport = yield payrollReportStore.getPayrollReport(rowsPerPage, currentPageToFilter, selectedMonthToFilter, filter);
            setPayrollInfo(payrollReport.payrollInfos);
            setCurrentPage(payrollReport.currentPage);
            setRowsPerPage(payrollReport.rowsPerPage);
        }
        catch (error) {
            catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
        }
        finally {
            setLoadingPayrollInfo(false);
        }
    });
    const handleMonthSelect = (month) => {
        setSelectedMonth(month);
        setCurrentPage(1);
    };
    const downloadCCBLinks = (link) => {
        openFileInNewTab(link);
    };
    const exportPayroll = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedMonth !== null) {
            try {
                const payrollReportExcel = yield payrollReportStore.exportPayrollReport(selectedMonth, currentFilter);
                downloadFileFromBuffer(payrollReportExcel, t("payrollReportTitle") + selectedMonth.month + "/" + selectedMonth.year + ".xlsx");
            }
            catch (error) {
                catchErrors(error, setErrorMessage, t("unexpectedErrorMessage"));
            }
        }
    });
    const { totalItems, totalAmount } = payrollSummary;
    const hasPayrollInfo = !loadingPayrollInfo && payrollInfo.length > 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionHeader, { title: t("payrollReportTitle"), actions: [
                {
                    label: t("exportSheetLabel"),
                    handleAction: () => exportPayroll(),
                    icon: React.createElement(Icon, { component: () => React.createElement(DownloadIcon, null) }),
                    disabled: payrollInfo.length === 0,
                },
            ] }),
        React.createElement(FilterForm, { className: s.chargeForm, items: items, handleSubmit: applyFilter }),
        React.createElement(SectionContainer, { className: s.container },
            months.length > 0 && React.createElement(MonthSelector, { months: months, handleSelect: handleMonthSelect }),
            React.createElement(ActionTable, { className: s.table, columns: columns, loading: loadingPayrollInfo, dataSource: payrollInfo.map((contract) => (Object.assign(Object.assign({}, contract), { key: `${contract.cpf}-${contract.assigneCompanyFromCession}-${!isEmpty(contract.ccb) ? contract.ccb.id : null}`, cpf: formatCpf(contract.cpf), creditor: contract.assigneCompanyFromCession, dueDate: getDateLocalTimezoneFormat(contract.dueDate), firstInstallmentDate: getDateLocalTimezoneFormat(contract.firstInstallmentDate), lastInstallmentDate: getDateLocalTimezoneFormat(contract.lastInstallmentDate), installment: (React.createElement(React.Fragment, null,
                        formatCurrentInstallment(contract.currentInstallmentNumber, contract.totalInstallments),
                        contract.extendedReason && (React.createElement(TooltipDisplay, { classname: s.tooltipContainer, title: React.createElement("div", { className: s.tooltipContainer },
                                React.createElement("p", null,
                                    "Reprograma\u00E7\u00E3o de parcelas referente a: ",
                                    contract.extendedReason)) })))), value: formatStringCurrencyNoPrefix(contract.value), ccbLink: (React.createElement(CCBButton, { ccb: contract.ccbLink, handleClick: () => {
                            downloadCCBLinks(contract.ccbLink);
                        } })) }))), locale: {
                    emptyText: !loadingPayrollInfo && payrollInfo.length === 0 && (React.createElement("div", { className: s.emptyTableMessage },
                        React.createElement("h1", null,
                            t("noDataMessage"),
                            " ",
                            React.createElement("br", null),
                            " Credifit ",
                            t("noDataPayrollMessage")))),
                } })),
        React.createElement("div", { className: s.footer }, hasPayrollInfo && React.createElement(SummaryItem, { title: "Total", value: formatStringCurrency(totalAmount) })),
        hasPayrollInfo && (React.createElement(SectionFooter, null,
            React.createElement(Pagination, { current: currentPage, total: totalItems, pageSize: rowsPerPage, onChange: (page) => setCurrentPage(page), onShowSizeChange: (current, size) => {
                    setCurrentPage(current);
                    setRowsPerPage(size);
                } })))));
});
export default PayRollReport;
