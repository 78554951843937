export const sharedTraslations = {
    contracts: {
        canceledByUserLabel: "Cancelado pelo usuário",
        canceledByCredifitLabel: "Cancelado pela Credifit",
        givenLabel: "Cedido",
        partialGivenLabel: "Cedido Parcialmente",
        pendingCessionLabel: "Cessão Pendente",
        closedLabel: "Finalizado",
        activeLabel: "Não Cedido",
        pendingHrLabel: "Pendente - RH",
        pendingCredifitLabel: "Pendente - Credifit",
        deniedHrLabel: "Recusado - RH",
        deniedCredifitLabel: "Recusado - Credifit",
        activeCompanyLabel: "Ativo",
        pendingIdentityValidationsLabel: "Validação de identidade pendente",
        created: "Criado",
    },
    proposals: {
        created: "Criada",
        pending_signature: "Aguardando assinatura",
        signed: "Assinada",
        refused: "Recusada",
        canceled: "Cancelada pelo Corban",
    },
};
